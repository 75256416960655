* {
  scroll-behavior: smooth;
  scroll-margin-top: 60px;
}

/* Scroll */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#root {
  height: 100vh;
}

html,
body,
#root,
.App {
  position: relative;
}

html {
  font-family: "Antenna", sans-serif;
  line-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 100% 16px;
}

@media (max-width: 850px) {
  html {
    font-size: 17px;
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

::selection {
  background-color: #fafafa;
  color: #595959;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Antenna", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 500;
  color: #e4e4e4;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
  color: #000;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  color: #e4e4e4;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  appearance: listbox;
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.App {
  overflow-x: hidden;
}

/* Flex */

.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexCenter {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.space {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

/* /Flex */

/* Adds */

.textCenter {
  text-align: center;
}

.relative {
  position: relative;
}

.section {
  padding: 70px 0;
}

.noPadBot {
  padding-bottom: 0;
}

.noPadTop {
  padding-top: 0;
}
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 850px) {
  .section {
    padding: 40px 0;
  }
}

@media (min-width: 768px) {
  .container-lg {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container-lg {
    width: 970px;
  }
}

@media (min-width: 1100px) {
  .container-lg {
    width: 1070px;
  }
}

@media (min-width: 1200px) {
  .container-lg {
    width: 1170px;
  }
}

@media (min-width: 1300px) {
  .container-lg {
    width: 1280px;
  }
}
