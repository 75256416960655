/* Models */
.descuento {
  position: absolute !important;
  top: -40px;
  right: -40px;
  background: #00095b;
  width: 190px;
  height: 190px;
  padding: 10px !important;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
}

.descuento * {
  padding: 0 !important;
  text-align: center;
  color: #fff;
}

.descuento h3 {
  font-size: 50px !important;
  line-height: 100%;
  margin-bottom: 10px;
}

.descuento h4 {
  font-size: 23px;
  line-height: 80%;
  margin-bottom: 0;
}

.descuento p {
  font-size: 18px;
}

.carCards {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 50px;
}

.carCards img {
  width: 130%;
  align-self: center;
  max-width: 750px;
  pointer-events: none;
}

.containCard span {
  font-weight: 900;
}

.containCard {
  display: flex;
  flex-direction: column;
  padding: 40px 20px 50px;
  position: relative;
  min-height: 320px;
  box-shadow: 0px 0rem 1rem 0 rgb(0 0 0 / 10%), 0px 0rem 2rem 0 rgb(0 0 0 / 10%);
  background: #fff;
  overflow: hidden;
}

.containCard * {
  position: relative;
}

.carCards h3 {
  font-family: "Antenna";
  font-weight: 900;
  font-size: 32px;
}

.carCards h2 {
  font-weight: 500;
  color: #00095b;
  font-size: 34px;
  margin-bottom: 10px;
}

.carCards p {
  color: #00095b;
  font-size: 17px;
  line-height: 1.5;
  margin: 0;
}

.carCards .resal {
  color: #00095b;
  font-weight: 500;
}

.cuota span {
  padding: 0 !important;
  font-size: 35px;
  margin-top: -5px;
  font-weight: 500;
}

.cuota {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #00095b;
  font-family: "Antenna";
}

.carCards .btn {
  width: fit-content;
  align-self: center;
}

.buttonDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}
.buttonDiv .btn {
  margin-top: 0;
  width: 100%;
  font-size: 16px;
}

.textBlackcard .cuota span {
  color: #00095b;
}
.cardDiv {
  justify-content: center;
  gap: 40px;
}
/* /Models */
@media (max-width: 991px) {
  .carCards {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  .cardDiv {
    justify-content: space-between;
  }
}

@media (max-width: 850px) {
  /* Models */
  .descuento {
    transform: scale(0.7);
    top: -62px;
    right: -45px;
    position: relative;
    z-index: 1;
  }

  .descuento p {
    font-size: 18px !important;
    color: #fff;
    font-weight: 500;
  }

  .carCards {
    width: inherit;
    margin-bottom: 50px;
    width: 100%;
  }

  .containCard {
    padding: 40px 20px 40px;
    margin-bottom: 20px;
  }

  .containCard::before {
    left: 0px;
    box-shadow: 5px 8px 42px 0 rgb(55 55 55 / 33%) !important;
  }

  .imgRed {
    width: 110% !important;
    margin-top: 0px !important;
  }

  .imgAmp {
    width: 140% !important;
    margin-top: -20px !important;
  }

  .carCards p {
    font-size: 17px;
  }

  .buttonDiv {
    width: 100%;
    margin-right: 0px;
  }

  /* /Models */
}
