.timerDiv {
  display: flex;
  align-self: center;
  padding: 20px 70px 30px;
  position: relative;
  align-items: center;
  background: #fff;
  column-gap: 10px;
}

.timerDiv h2 {
  margin-bottom: 0;
  font-weight: 400;
}
.timerDiv span {
  color: #00095b;
  font-weight: 900;
}

/* Media */

@media (max-width: 850px) {
  .fullH h3 {
    font-size: 16px;
  }
  .timerDiv {
    flex-direction: column;
    padding: 20px 20px 27px;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
  }
}
