.testimonioSwiper {
  width: 100%;
  display: flex;
}
.testimonioSwiper .swiper-slide {
  background: #00095b;
  margin-bottom: 50px;
  color: #fff;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.testimonioSwiper :is(h3, h4) {
  margin-bottom: 0;
}
.testimonioSwiper h3 {
  font-family: "Antenna";
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
}
.testimonioSwiper h4 {
  font-size: 18px;
  font-weight: 400;
}
.testimonioSwiper .swiper-pagination-bullet {
  border: #00095b solid 1px;
}
