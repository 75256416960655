.fullH {
  margin-bottom: 0px;
  background: url(../assets/hero.avif) center;
  background-size: cover;
  min-height: 550px;
  display: flex;
  align-items: center;
  position: relative;
}
.fullH::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00095b;
  opacity: 0.2;
}
.fullH h1 {
  color: #fafafa;
  font-weight: 400;
}

.fullH h4 {
  color: #ffffff8c;
  font-weight: 500;
  margin-bottom: 0;
}
.fullH .h1 span {
  color: #fff;
  font-weight: 400;
}

.fullH h3 {
  color: #fff;
  font-weight: 400;
}

.textCol {
  width: 60%;
  max-width: 650px;
  position: relative;
}

.fullw {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: 100px !important;
}

/* Media */

@media (max-width: 850px) {
  .colorBox {
    padding: 0;
    flex-direction: column;
  }
  .textCol {
    width: 100%;
    max-width: 450px;
  }
}
