.header {
  top: 0;
  width: 100%;
  height: 90px;
  z-index: 9999;
  background: #fff;
}

.header img {
  width: 300px;
  height: auto;
}
.header h3 {
  margin: 5px 0;
  color: #00095b;
  font-weight: 400;
  font-size: 21px;
}

@media (max-width: 850px) {
  /* Header */
  .header img {
    width: 200px;
  }
  .logo:before {
    width: 41%;
  }
  .logo:after {
    width: 41%;
  }
  .header {
    height: 80px;
    padding: 0;
  }
  .header h3 {
    margin: 5px 0;
    align-self: flex-start;
    margin-left: 20px;
    position: absolute;
    top: 6px;
    font-weight: 100;
    font-size: 17px;
  }
  .header svg {
    width: 37px;
    height: auto;
  }
  /* /Header */
}
